import React, { Fragment } from "react";
import { host, pesan, api, isJson, submitForm, checkDateFormat } from './Module';
import "./App.css";

function App() {
  let Token = localStorage.getItem("TokenNewStudent");

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Profile: {},
        PPDB: {},
        EMAIL: "",
        NAMA: "",
        NO_HANDPHONE: "",
        ALAMAT: "",
        Pwd: ""
      };
    }

    async componentDidMount() {
      let sql = await api("profile", { act: "profile" });
      if (sql.status == "sukses") {
        let ppdb = await api("controler/ppdb_api", { act: "profile" }, true);
        this.setState({ Profile: sql.data, PPDB: ppdb.data });
        document.title = sql.data.Nama;
      }
    }

    handleChange(Mode) {
      const userForms = document.getElementById('user_options-forms')
      if (Mode == "Daftar") {
        userForms.classList.remove('bounceRight')
        userForms.classList.add('bounceLeft')
      } else {
        userForms.classList.remove('bounceLeft')
        userForms.classList.add('bounceRight')
      }
    }

    handleSuccess(data) {
      this.setState({ NAMA: "", EMAIL: "", NO_HANDPHONE: "", ALAMAT: "" });
      this.handleChange("Masuk");
    }

    handleLogin(data) {
      if (data.status == "sukses") {
        localStorage.setItem("TokenNewStudent", data.Token);
        window.location.reload();
      } else {
        alert(data.pesan);
      }
    }

    render() {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      return (
        <Fragment>
          {screenWidth > 480 ?
            <section className="user">
              <div className="user_options-container">
                <div className="user_options-text">
                  <div className="user_options-unregistered">
                    <h2 className="user_unregistered-title">Pendaftaran Peserta Didik Baru Gelombang {this.state.PPDB.Gelombang}</h2>
                    <p className="user_unregistered-text">{this.state.PPDB.Keterangan}</p>
                    <button className="user_unregistered-signup" id="signup-button" onClick={() => this.handleChange("Daftar")}>Daftar</button>
                  </div>

                  <div className="user_options-registered">
                    <h2 className="user_registered-title">Sudah memiliki akun?</h2>
                    <p className="user_registered-text">Jika anda sudah memiliki akun dari panitia, silahkan login untuk mengisi data lengkap</p>
                    <button className="user_registered-login" id="login-button" onClick={() => this.handleChange("Login")}>Login</button>
                  </div>
                </div>

                <div className="user_options-forms" id="user_options-forms">
                  <div className="user_forms-login">
                    <h2 className="forms_title">Login</h2>
                    <form className="forms_form" onSubmit={(e) => submitForm(e, { crud: 'controler/ppdb_api', debug: true, fn: (e) => this.handleLogin(e) })}>
                      <input type="hidden" name="act" value="login" />

                      <fieldset className="forms_fieldset">
                        <div className="forms_field">
                          <input type="email" name="Email" placeholder="Email" className="forms_field-input" required autoFocus />
                        </div>
                        <div className="forms_field">
                          <input type="password" name="Pwd" placeholder="Password" className="forms_field-input" required />
                        </div>
                      </fieldset>
                      <div className="forms_buttons">
                        <button type="button" className="forms_buttons-forgot">Lupa Password?</button>
                        <button type="submit" className="forms_buttons-action"><i className="fas fa-sign-in-alt"></i> Masuk</button>
                      </div>
                    </form>
                  </div>

                  <div className="user_forms-signup">
                    <h2 className="forms_title">Daftar</h2>
                    <form className="forms_form" onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', debug: true, fn: (e) => this.handleSuccess(e) })}>
                      <input type="hidden" name="act" value="daftar" />
                      <input type="hidden" name="IDTahunAjaran" value={this.state.PPDB.IDTahunAjaran} />
                      <input type="hidden" name="IDPPDB" value={this.state.PPDB.ID} />
                      <fieldset className="forms_fieldset">
                        <div className="forms_field">
                          <input type="text" placeholder="Nama Lengkap" name="NAMA" value={this.state.NAMA} onChange={(e) => {
                            this.setState({ NAMA: e.target.NAMA });
                          }} className="forms_field-input" required />
                        </div>
                        <div className="forms_field">
                          <input type="email" placeholder="Alamat Email" name="EMAIL" value={this.state.EMAIL} onChange={(e) => {
                            this.setState({ EMAIL: e.target.value });
                          }} className="forms_field-input" required />
                        </div>
                        <div className="forms_field">
                          <input type="number" placeholder="No Telp" name="NO_HANDPHONE" value={this.state.NO_HANDPHONE} onChange={(e) => {
                            this.setState({ NO_HANDPHONE: e.target.value });
                          }} className="forms_field-input" required />
                        </div>
                        <div className="forms_field">
                          <input type="text" placeholder="Alamat" name="ALAMAT" value={this.state.ALAMAT} onChange={(e) => {
                            this.setState({ ALAMAT: e.target.value });
                          }} className="forms_field-input" required />
                        </div>
                      </fieldset>
                      <div className="forms_buttons">
                        <button type="submit" className="forms_buttons-action"><i className="fas fa-save"></i> Daftar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section> :
            <div className="container bg" style={{ paddingTop: "100px" }}>
              <div className="card shadow-lg" style={{ backgroundColor: "#32642d", color: "#FBB557" }}>
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <img src={require("./logo.png")} style={{ width: "50px", height: "50px" }} />
                    <h5>MA Darul Ulum</h5>
                  </div>
                </div>
                <div className="card-body">
                  <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Daftar</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Masuk</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                      <p></p>

                      <form className="forms_form" onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', debug: true, fn: (e) => this.handleSuccess(e) })}>
                        <input type="hidden" name="act" value="daftar" />
                        <input type="hidden" name="IDTahunAjaran" value={this.state.PPDB.IDTahunAjaran} />
                        <input type="hidden" name="IDPPDB" value={this.state.PPDB.ID} />
                        <div className="form-group">
                          <label>Nama</label>
                          <input type="text" className="form-control form-control-sm" name="NAMA" value={this.state.NAMA} onChange={(e) => this.setState({ NAMA: e.target.value })} placeholder="Silahkan masukan nama" required />
                          <div className="invalid-feedback">Silahkan isi Nama</div>
                        </div>
                        <p></p>
                        <div className="form-group">
                          <label>Email</label>
                          <input type="email" className="form-control form-control-sm" name="EMAIL" value={this.state.EMAIL} onChange={(e) => this.setState({ EMAIL: e.target.value })} placeholder="Silahkan masukan email" required />
                          <div className="invalid-feedback">Silahkan isi email</div>
                        </div>
                        <p></p>
                        <div className="form-group">
                          <label>Telp</label>
                          <input type="number" className="form-control form-control-sm" name="NO_HANDPHONE" value={this.state.NO_HANDPHONE} onChange={(e) => this.setState({ NO_HANDPHONE: e.target.value })} placeholder="Silahkan masukan telp" required />
                          <div className="invalid-feedback">Silahkan isi telp</div>
                        </div>
                        <p></p>
                        <div className="form-group">
                          <label>Alamat</label>
                          <input type="text" className="form-control form-control-sm" name="ALAMAT" value={this.state.ALAMAT} onChange={(e) => this.setState({ ALAMAT: e.target.value })} placeholder="Silahkan masukan alamat" required />
                          <div className="invalid-feedback">Silahkan isi alamat</div>
                        </div>
                        <p></p>
                        <div className="d-flex gap-1">
                          <button className="btn w-100 btn-warning"><i className="fas fa-save"></i> Daftar</button>
                        </div>
                      </form>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                      <h6>Silahkan masuk dengan akun yang telah di berikan oleh panitia pendaftaran</h6>
                      <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control form-control-sm" name="Email" placeholder="Silahkan masukan email" required />
                        <div className="invalid-feedback">Silahkan isi email</div>
                      </div>
                      <p></p>
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control form-control-sm" name="Pwd" placeholder="Silahkan masukan password" required />
                        <div className="invalid-feedback">Silahkan isi password</div>
                      </div>
                      <p></p>
                      <div className="d-flex gap-1">
                        <button className="btn w-100 btn-warning"><i className="fas fa-sign-in-alt"></i> Masuk</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </Fragment>
      )
    }
  }

  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Detail: {},
        Profile: {}
      };
    }

    async componentDidMount() {
      let sql = await api("profile", { act: "profile" });
      if (sql.status == "sukses") {
        let ppdb = await api("controler/ppdb_api", { act: "detail murid" }, true);
        this.setState({ Detail: ppdb.data });
        document.title = sql.data.Nama;
      }
    }

    render() {
      return (
        <Fragment>
          <div className="container">
            <p></p>
            <div className="d-flex justify-content-between align-items-center">
              <img src={require("./logo.png")} style={{ width: "100px", height: "100px" }} />
              <h5></h5>
            </div>
            <p></p>
            <h6>Silahkan melengkapi data berikut :</h6>
            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
              <input type='hidden' name="ID" value={this.state.Detail.ID} />
              <input type='hidden' name="act" value="update formulir" />
              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Nama</label>
                    <input type="text" className='form-control form-control-sm' name="NAMA" value={this.state.Detail.NAMA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NAMA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan Nama</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Email</label>
                    <input type="email" className='form-control form-control-sm' name="EMAIL" value={this.state.Detail.EMAIL} onChange={(e) => {
                      let data = this.state.Detail;
                      data.EMAIL = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan Email</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Telp</label>
                    <input type="text" className='form-control form-control-sm' name="NO_HANDPHONE" value={this.state.Detail.NO_HANDPHONE} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NO_HANDPHONE = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NO_HANDPHONE</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Alamat</label>
                    <input type="text" className='form-control form-control-sm' name="ALAMAT" value={this.state.Detail.ALAMAT} onChange={(e) => {
                      let data = this.state.Detail;
                      data.ALAMAT = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan ALAMAT</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tempat Lahir</label>
                    <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR" value={this.state.Detail.TEMPAT_LAHIR} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TEMPAT_LAHIR = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tgl. Lahir</label>
                    <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR)} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TANGGAL_LAHIR = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>NIK</label>
                    <input type="text" className='form-control form-control-sm' name="NIK" value={this.state.Detail.NIK} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NIK = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NIK</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>NISN</label>
                    <input type="text" className='form-control form-control-sm' name="NISN" value={this.state.Detail.NISN} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NISN = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NISN</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Jenis Kelamin</label>
                    <input type="text" className='form-control form-control-sm' name="JENIS_KELAMIN" value={this.state.Detail.JENIS_KELAMIN} onChange={(e) => {
                      let data = this.state.Detail;
                      data.JENIS_KELAMIN = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan JENIS_KELAMIN</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Agama</label>
                    <input type="text" className='form-control form-control-sm' name="AGAMA" value={this.state.Detail.AGAMA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.AGAMA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan AGAMA</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Status Alamat</label>
                    <input type="text" className='form-control form-control-sm' name="STATUS_ALAMAT" value={this.state.Detail.STATUS_ALAMAT} onChange={(e) => {
                      let data = this.state.Detail;
                      data.STATUS_ALAMAT = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan STATUS_ALAMAT</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tlp. Orang Tua</label>
                    <input type="text" className='form-control form-control-sm' name="NO_HP_ORANG_TUA" value={this.state.Detail.NO_HP_ORANG_TUA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NO_HP_ORANG_TUA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NO_HP_ORANG_TUA</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Jarak Ke Sekolah</label>
                    <input type="text" className='form-control form-control-sm' name="JARAK_KE_SEKOLAH" value={this.state.Detail.JARAK_KE_SEKOLAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.JARAK_KE_SEKOLAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan JARAK_KE_SEKOLAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Transportasi Ke Sekolah</label>
                    <input type="text" className='form-control form-control-sm' name="TRANSPORTASI_KE_SEKOLAH" value={this.state.Detail.TRANSPORTASI_KE_SEKOLAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TRANSPORTASI_KE_SEKOLAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TRANSPORTASI_KE_SEKOLAH</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Cita-cita</label>
                    <input type="text" className='form-control form-control-sm' name="CITA_CITA" value={this.state.Detail.CITA_CITA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.CITA_CITA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan CITA_CITA</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Hobby</label>
                    <input type="text" className='form-control form-control-sm' name="HOBBY" value={this.state.Detail.HOBBY} onChange={(e) => {
                      let data = this.state.Detail;
                      data.HOBBY = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan HOBBY</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Asal Sekolah</label>
                    <input type="text" className='form-control form-control-sm' name="ASAL_SEKOLAH" value={this.state.Detail.ASAL_SEKOLAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.ASAL_SEKOLAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan ASAL_SEKOLAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Nama Sekolah</label>
                    <input type="text" className='form-control form-control-sm' name="NAMA_SEKOLAH" value={this.state.Detail.NAMA_SEKOLAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NAMA_SEKOLAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NAMA_SEKOLAH</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Alamat Sekolah</label>
                    <input type="text" className='form-control form-control-sm' name="ALAMAT_SEKOLAH" value={this.state.Detail.ALAMAT_SEKOLAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.ALAMAT_SEKOLAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan ALAMAT_SEKOLAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>No. UN</label>
                    <input type="text" className='form-control form-control-sm' name="NOMOR_UN" value={this.state.Detail.NOMOR_UN} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NOMOR_UN = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NOMOR_UN</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Status Keluarga</label>
                    <input type="text" className='form-control form-control-sm' name="STATUS_KELUARGA" value={this.state.Detail.STATUS_KELUARGA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.STATUS_KELUARGA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan STATUS KELUARGA</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Anak ke</label>
                    <input type="text" className='form-control form-control-sm' name="ANAK_KE" value={this.state.Detail.ANAK_KE} onChange={(e) => {
                      let data = this.state.Detail;
                      data.ANAK_KE = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan ANAK KE</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Jumlah Saudara</label>
                    <input type="text" className='form-control form-control-sm' name="JUMLAH_SAUDARA" value={this.state.Detail.JUMLAH_SAUDARA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.JUMLAH_SAUDARA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan Jumlah SAUDARA</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>No. KK</label>
                    <input type="text" className='form-control form-control-sm' name="NO_KK" value={this.state.Detail.NO_KK} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NO_KK = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NO_KK</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Nama Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="NAMA_AYAH" value={this.state.Detail.NAMA_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NAMA_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NAMA_AYAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tempat Tgl. Lahir Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_AYAH" value={this.state.Detail.TEMPAT_LAHIR_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TEMPAT_LAHIR_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_AYAH</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tgl. Lahir Ayah</label>
                    <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_AYAH" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_AYAH)} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TANGGAL_LAHIR_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_AYAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>NIK Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="NIK_AYAH" value={this.state.Detail.NIK_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NIK_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NIK_AYAH</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Pendidikan Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_AYAH" value={this.state.Detail.PENDIDIKAN_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PENDIDIKAN_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_AYAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Status Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="STATUS_AYAH" value={this.state.Detail.STATUS_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.STATUS_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan STATUS_AYAH</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Pekerjaan Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="PEKERJAAN_AYAH" value={this.state.Detail.PEKERJAAN_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PEKERJAAN_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_AYAH</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Penghasilan Ayah</label>
                    <input type="text" className='form-control form-control-sm' name="PENGHASILAN_AYAH" value={this.state.Detail.PENGHASILAN_AYAH} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PENGHASILAN_AYAH = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_AYAH</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Nama Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="NAMA_IBU" value={this.state.Detail.NAMA_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NAMA_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NAMA_IBU</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tempat Lahir Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_IBU" value={this.state.Detail.TEMPAT_LAHIR_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TEMPAT_LAHIR_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_IBU</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tgl. Lahir Ibu</label>
                    <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_IBU" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_IBU)} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TANGGAL_LAHIR_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_IBU</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>NIK Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="NIK_IBU" value={this.state.Detail.NIK_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NIK_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NIK_IBU</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Pendidikan Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_IBU" value={this.state.Detail.PENDIDIKAN_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PENDIDIKAN_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_IBU</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Status Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="STATUS_IBU" value={this.state.Detail.STATUS_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.STATUS_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan STATUS_IBU</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Pekejraan Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="PEKERJAAN_IBU" value={this.state.Detail.PEKERJAAN_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PEKERJAAN_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_IBU</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Penghasilan Ibu</label>
                    <input type="text" className='form-control form-control-sm' name="PENGHASILAN_IBU" value={this.state.Detail.PENGHASILAN_IBU} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PENGHASILAN_IBU = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_IBU</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Alamat Orang Tua</label>
                    <input type="text" className='form-control form-control-sm' name="ALAMAT_ORANG_TUA" value={this.state.Detail.ALAMAT_ORANG_TUA} onChange={(e) => {
                      let data = this.state.Detail;
                      data.ALAMAT_ORANG_TUA = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan ALAMAT_ORANG_TUA</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Jumlah Tanggungan</label>
                    <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN" value={this.state.Detail.JUMLAH_TANGGUNGAN} onChange={(e) => {
                      let data = this.state.Detail;
                      data.JUMLAH_TANGGUNGAN = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan JUMLAH_TANGGUNGAN</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Kartu Jaminan</label>
                    <input type="text" className='form-control form-control-sm' name="KARTU_JAMINAN" value={this.state.Detail.KARTU_JAMINAN} onChange={(e) => {
                      let data = this.state.Detail;
                      data.KARTU_JAMINAN = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan KARTU_JAMINAN</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Nama Wali</label>
                    <input type="text" className='form-control form-control-sm' name="NAMA_WALI" value={this.state.Detail.NAMA_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NAMA_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NAMA_WALI</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tempat Lahir Wali</label>
                    <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_WALI" value={this.state.Detail.TEMPAT_LAHIR_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TEMPAT_LAHIR_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_WALI</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Tgl. Lahir Wali</label>
                    <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_WALI" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_WALI)} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TANGGAL_LAHIR_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_WALI</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Telp Wali</label>
                    <input type="number" className='form-control form-control-sm' name="TELP_WALI" value={this.state.Detail.TELP_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.TELP_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan telp wali</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Hubungan Dengan Wali</label>
                    <input type="text" className='form-control form-control-sm' name="HUBUNGAN_WALI" value={this.state.Detail.HUBUNGAN_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.HUBUNGAN_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan hubungan dengan wali</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>NIK Wali</label>
                    <input type="text" className='form-control form-control-sm' name="NIK_WALI" value={this.state.Detail.NIK_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.NIK_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan NIK_WALI</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Pendidikan Wali</label>
                    <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_WALI" value={this.state.Detail.PENDIDIKAN_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PENDIDIKAN_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_WALI</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Pekerjaan Wali</label>
                    <input type="text" className='form-control form-control-sm' name="PEKERJAAN_WALI" value={this.state.Detail.PEKERJAAN_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PEKERJAAN_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_WALI</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Penghasilan Wali</label>
                    <input type="text" className='form-control form-control-sm' name="PENGHASILAN_WALI" value={this.state.Detail.PENGHASILAN_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.PENGHASILAN_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className="row">
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Alamat Wali</label>
                    <input type="text" className='form-control form-control-sm' name="ALAMAT_WALI" value={this.state.Detail.ALAMAT_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.ALAMAT_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan ALAMAT_WALI</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className='form-group form-group-sm'>
                    <label>Jml. Tanggungan Wali</label>
                    <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN_WALI" value={this.state.Detail.JUMLAH_TANGGUNGAN_WALI} onChange={(e) => {
                      let data = this.state.Detail;
                      data.JUMLAH_TANGGUNGAN_WALI = e.target.value;
                      this.setState({ Detail: data });
                    }} required />
                    <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                  </div>
                </div>
              </div>

              <p></p>

              <div className='form-group form-group-sm'>
                <label>PAUD</label>
                <select className='form-select form-select-sm' name="PAUD" value={this.state.Detail.PAUD} onChange={(e) => {
                  let data = this.state.Detail;
                  data.PAUD = e.target.value;
                  this.setState({ Detail: data });
                }} >
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </select>
                <div className='invalid-feedback'>Silahkan pilih</div>
              </div>
              <p></p>
              <button type="submit" className="btn btn-success w-100"><i className="fas fa-save"></i> Simpan</button>
            </form>
          </div>
        </Fragment>
      )
    }
  }
  if (Token) {
    return <Main />;
  } else {
    return <Login />;
  }
}

export default App;
